import request from "@/utils/request";

// 查找软件
export function solveProduct(domainParent) {
  return request.get(`/goods/software/productByDomainParent/${domainParent}`);
  // return request.get(`/software/productByDomainParent/${domainParent}`);
}

// 查找硬件

export function solveSoftware(domainParent) {
  return request.get(`/goods/software/softwareByDomainParent/${domainParent}`);
  // return request.get(`/software/softwareByDomainParent/${domainParent}`);
}

// 查询场景信息
export function domainById(id) {
  return request.get(`/goods/software/domainById/${id}`);
}

//查询场景下的软件
export function listByDomainId(domainId) {
  return request.get("/goods/software/listByDomainId?domainId=" + domainId);
}